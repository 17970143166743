@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.button {
  width: 58px;
  height: 32px;
  border-radius: 2px;
  background-color: var(--light-background-overflow);
  border-radius: 2px 16px 16px 2px;
}
