@use "sass:map";
@import "@assets/styles/mixins.module.scss";

.listPostal {
  overflow: hidden;
  border-radius: 12px;
}

.button {
  width: 100%;
}

.wrapperCity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 11px 23px 11px 20px;
}

.cityName {
  display: inline-block;
  @include text-16;
  color: var(--light_accent_main);
}

.wrapperIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.arrow {
  transition: transform .5s ease-in-out;

  &Rotated {
    transform: rotate(180deg);
  }
}

.listPostalsOpen {
  transition: max-height .5s ease-in-out;
}

.listPostalsClosed {
  overflow: hidden;
  max-height: 0;
  transition: max-height .5s ease-in-out;
}

.wrapperPostals {
  padding: 0 20px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: var(--light-background-primary);
  overflow: hidden;
  list-style: none;
}

.choicer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-top: .5px solid var(--light_additional_separator);
  transition: max-height .5s ease-in-out, opacity .5s ease-in-out, padding .5s ease-in-out, border .5s ease-in-out;

  &.hidden {
    max-height: 0;
    padding: 0;
    opacity: 0;
    border: 0;
  }

  &.visible {
    max-height: 41px;
    padding: 10px 0;
    opacity: 1;
  }
}

.wrapperButton {
  display: flex;
  width: 100%;
  max-height: 36px;
  padding-top: 16px;
  justify-content: center;
  transition: max-height .5 ease-in-out, padding .5s ease-in-out;

  &Hidden {
    max-height: 0;
    padding: 0;
  }
}

.buttonShow {
  @include text-16;
  color: var(--light_accent_supplemental);
}
