@use 'sass:map';
@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.type {
  opacity: 1;
  visibility: visible;
  transition:
    max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out,
    border 0.5s ease-in-out;

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--light_additional_separator);
  }

  &.hidden {
    max-height: 0;
    border: none;
    opacity: 0;
    visibility: hidden;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
}

.text {
  @include text-16;
  color: var(--light_accent_main);
}

.buttonWrapper {
  display: flex;
  justify-content: right;
  align-items: center;
}

.button {
  max-width: 20px;
  height: 20px;
  margin-left: 17px;
  border-radius: 50%;
  overflow: hidden;
  font-size: 0;
  opacity: 1;
  transition:
    max-width 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    margin-left 0.5s ease-in-out;

  &.hidden {
    max-width: 0;
    opacity: 0;
    margin-left: 0;
  }
}

.customCheck {
  position: relative;
  display: flex;
  width: 20px;
  height: 100%;
  border: 2px solid var(--light_label_tertiary);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-block;
  background-color: var(--light-background-primary);

  &Active {
    border-color: var(--light_label_secondary);
  }

  &Select {
    border: none;
    background-color: var(--light_label_tertiary);

    &Active {
      background-color: var(--light_label_secondary);
    }
  }
}

.done {
  position: absolute;
  top: 0;
  left: 0;
}

.modalHeader {
  margin-bottom: 18px;
  padding-top: 7px;
}

.modalText {
  max-width: 313px;
  margin: 0 auto 14px;
}

.wrapperSubTypes {
  padding: 0 20px;
  opacity: 1;
  visibility: visible;
  transition:
    max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    visibility 0.5s ease-in-out;

  &.hidden {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &.separator {
    border-top: 0.5px solid var(--light_additional_separator);
  }
}

.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
}

.subType {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  &:not(:last-child) {
    border-bottom: 0.5px solid var(--light_additional_separator);
  }

  &Text {
    @include text-16;
    color: var(--light_label_tertiary);
  }
}

.time {
  min-width: 82px;
  padding: 7px;
  border-radius: 50px;
  @include text-14;
  transition: color 0.5s ease-in-out;

  &:disabled {
    opacity: 1;
    pointer-events: none;
    color: var(--light-background-primary);
  }
}

.arrow {
  transition: transform 0.5s ease-in-out;

  &Rotated {
    transform: rotate(180deg);
  }
}
