@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.button {
  width: 100%;
}

.wrap {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: var(--light-background-primary);
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &Active {
    padding: 20px;
    max-height: 420px;
    height: 100%;
  }
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.titleButton {
  border-radius: 50%;
  width: 70%;
  color: var(--light_accent_main);
  background-color: var(--light-background-overflow);
}

.clearButton {
  border-radius: 50%;
  color: var(--light_accent_main);
  background-color: var(--light-background-overflow);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.item {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding: 20px 0;
  border: none;
  @include text-16;
  color: var(--light_accent_main);

  &::placeholder {
    color: var(--light_label_tertiary);
  }
}

.separator {
  position: absolute;
  width: calc(100% + 20px);
  height: 1px;
  background-color: var(--light_additional_separator);
  bottom: 0;
  left: 0;
}

.doubleFilters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  position: relative;
  width: 100%;
}

.dateBlock {
  background-color: transparent;
  height: auto;
}

.doubleFilter {
  width: 135px;
  border: none;
  text-align: center;
  background-color: transparent;
  @include text-16;
  color: var(--light_label_tertiary);
  text-align: left;

  &::placeholder {
    color: var(--light_label_tertiary);
    text-align: left;
  }
}

.right {
  text-align: right;
  &::placeholder {
    text-align: right;
  }
}

.doubleFiltersSeparator {
  width: 1px;
  height: 24px;
  background-color: var(--light_additional_separator);
}
