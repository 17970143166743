@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.selector {
  position: relative;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 16px);
    left: 0;
    right: 0;
    height: 32px;
    border-radius: 8px;
    background-color: var(--black_04);
  };
};

.buttonSave {
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  @include text-16;
  color: var(--light-background-primary);
  letter-spacing: -0.31px;

  &Wrapper {
    margin-top: 30px;
  };
};

.drum {
  display: flex;

  &Left {
    justify-content: left
  };

  &Right {
    justify-content: right
  };

  &Wrapper {
    width: 50%;

    & .drum{
      &Item {
        width: 50px;
        text-align: center;
      };
    };
  };
};

.error {
  margin-top: 10px;
  @include text-14;
  color: var(--light_accent_negative);
  text-align: center;
}
