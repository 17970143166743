@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  background-position: center;
  background-repeat: no-repeat;
  padding: 231px 20px 25px 20px;
}

.title {
  margin-top: 16px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  color: var(--light-background-primary);
  letter-spacing: -0.52px;
}

.text {
  margin-top: 16px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: var(--light-background-primary);
  letter-spacing: -0.52px;
}

@media (max-width: 767px) {
  .wrapper {
    padding: 32px 20px;
  }

  .title {
    font-size: 16px;
  }

  .text {
    margin-top: 8px;
    font-size: 12px;
  }
}
