@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  max-width: 350px;
  min-width: calc(100% - 40px);
  background-color: var(--light-background-primary);
}

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.wrap {
  position: relative;
}

.header {
  position: relative;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 16px 0;
}

.item {
  border: 1px solid var(--light_accent_main);
  border-radius: 12px;
  max-width: 300px;
  width: 100%;
  padding: 10px 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include text-16;
  color: var(--light_accent_main);
}

.contactBlock {
  padding: 20px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.button {
  @include text-16;
  text-decoration: none;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--light-background-primary);
  background-color: var(--light_accent_positive);
  border-radius: 12px;
  cursor: pointer;

  &.email {
    background-color: var(--light_accent_supplemental);
  }
}

.text {
  max-width: 185px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
