.buttonGroup {
  display: flex;
  align-items: center;
  gap: 2px;
}

.button {
  &:first-child {
    border-radius: 50px 0 0 50px;
  }

  &:last-child {
    border-radius: 0 50px 50px 0;
  }

  &.noSendButton {
    border-radius: 50px;
  }
}

.size {
  padding: 5px 19px;
}
