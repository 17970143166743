@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.documentsList {
  list-style: none;
}

.documentItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18.5px 0 0;
}

.skeletonItem {
  margin-top: 20px;
  padding: 0px 16px;
  border-radius: 20px;
}

skeletonItem:not(:last-child) {
  margin-bottom: 20px;
  border-bottom: 0.5px solid var(--light_additional_separator);
}

.documentItem:not(:last-child) {
  padding-bottom: 17px;
  border-bottom: 0.5px solid var(--light_additional_separator);
}

.document {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.documentName {
  margin-left: 10px;
  @include text-14;
  color: var(--light_label_tertiary);
}

.documentLink {
  @include text-16;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  color: var(--light_accent_supplemental);
}

.button {
  @include text-14;
}

.buttonDisabled {
  pointer-events: none;
}

.declined {
  color: var(--light_accent_negative);
}

.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 24px;
  overflow: hidden;
}

.iconWrapper {
  max-width: 24px;
  opacity: 1;
  transition:
    max-width 0.5s ease-in-out,
    opacity 0.5s ease-in-out;

  &.hidden {
    max-width: 0;
    opacity: 0;
  }
}

.pending {
  color: var(--light_accent_neutral);
}

.approved {
  color: var(--light_accent_positive);
}

.inactive {
  color: var(--light_label_tertiary);
}

.disabledDeclined {
  color: var(--light_label_tertiary);
}

.expiringApproved {
  color: var(--light_accent_neutral);
}
.disabledApproved {
  color: var(--light_label_tertiary);
}
