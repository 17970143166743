@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapper {
  padding-top: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100dvh;
  gap: 40px;
  max-width: 350px;
  margin: 0 auto;
}

.link {
  text-decoration: none;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 96px;
}

.title {
  display: block;
  color: var(--light_label_primary);
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 48px;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.red {
  color: var(--light_accent_negative);
}

.blue {
  color: var(--light_accent_supplemental);
}

.error {
  @include text-14;
  color: var(--light_accent_negative);
  text-align: center;
}
