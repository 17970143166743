@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.button {
  width: 100%;
}

.modal {
  width: 100%;
}

.header {
  @include text-18;
  justify-content: center;
  text-align: center;
  color: var(--light_label_secondary);
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item {
  @include text-16;
  color: var(--light_accent_main);
  width: 100%;
  text-align: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--light_additional_separator);
}

.item:last-child {
  border-bottom: none;
}
