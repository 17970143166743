@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.wrap {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 16px;
}

.text {
  @include text-18;
  color: var(--light_label_primary);
}

.block {
  background-color: var(--light-background-primary);
  padding: 16px 20px;
  border-radius: 20px;
}

.line {
  @include text-16;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 0.5px solid var(--light_additional_separator);

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }
}

.key {
  color: var(--light_label_tertiary);

  &::first-letter {
    text-transform: uppercase;
  }
}

.value {
  color: var(--light_accent_main);
}

.linkButton {
  text-decoration: underline;
  cursor: pointer;
  color: var(--light_accent_supplemental);
}

.buttonBlock {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.link {
  text-decoration: none;
}
.button {
  width: 100%;
}
