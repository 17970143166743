@use "sass:map";
@import "@assets/styles/mixins.module.scss";

.wrapperChoicer {
  list-style: none;
  margin: 0;
}

.choicer {
  overflow: hidden;
  transition: max-height .5s ease-in-out, opacity .5s ease-in-out, margin .5s ease-in-out;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  };

  &LastVisiblePosition {
    margin-bottom: 0;
  }
}

.hidden {
  max-height: 0;
  opacity: 0;

  &:not(:last-child) {
    margin-bottom: 0px;
  };
}

.visible {
  opacity: 1;

  &Open {
    margin-bottom: 0px;
  }
}

.wrapperButton {
  display: flex;
  width: 100%;
  padding-top: 16px;
  justify-content: center;

  &Hidden {
    padding-top: 0;
  }
}

.button {
  @include text-16;
  color: var(--light_accent_supplemental);
  background-color: transparent;
  border: none;
  transition: max-height .5s ease-in-out, opacity .5s ease-in-out;

  &Visible {
    max-height: 20px;
    opacity: 1;
  };

  &Hidden {
    max-height: 0;
    opacity: 0;
    cursor: auto;
  }

  &:disabled {
    opacity: 0;
    display: none;
  }
}
