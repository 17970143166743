@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapper {
  min-width: 315px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.header {
  justify-content: center;
  margin-bottom: 18px;
  @include text-18;
}

.text {
  @include text-14;
  text-decoration: none;
  display: block;
  text-align: center;
  color: var(--light_accent_main);
  max-width: 315px;
  word-wrap: break-word;
}

.button {
  width: 100%;
  padding: 10px 32px;
  border-radius: 12px;
}
