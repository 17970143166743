@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.wrap {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.switchBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--light_accent_supplemental);
  border-radius: 16px;
  width: 77px;

  &.inactive {
    flex-direction: row-reverse;
    background-color: var(--light_accent_supplemental);
    gap: 4px;

    .switchIcon {
      background-color: var(--light-background-primary);
    }

    .switchText {
      color: var(--light-background-primary);
    }
  }
}

.switchIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--light_accent_supplemental);
  border-radius: 50%;
}

.switchText {
  width: 42px;
  color: var(--light_accent_supplemental);
  @include text-12;
}

.text {
  @include text-18;
  color: var(--light_label_primary);
}

.content {
  padding-top: 16px;
}

.search {
  height: 48px;
}

.searchIcon {
  position: absolute;
  top: 18px;
  left: 10px;
}

.list {
  position: relative;
  padding: 20px 0;
}

.empty {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
}

.card {
  position: relative;
  background-color: var(--light-background-primary);
  border-radius: 20px;
  padding: 16px 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.cardSeparator {
  width: calc(100% - 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: 20px;
}

.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include text-16;

  &:first-child {
    color: var(--light_accent_supplemental);
    padding-bottom: 10px;
  }

  &:last-child {
    padding-top: 10px;
  }
}

.penalty {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.wrapperButton {
  display: flex;
  width: 100%;
  padding-top: 16px;
  justify-content: center;

  &Hidden {
    padding-top: 0;
  }
}

.button {
  @include text-16;
  color: var(--light_accent_supplemental);
  background-color: transparent;
  border: none;
  transition:
    max-height 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
}
