@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  width: 100%;
  min-height: 95dvh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 50px);
}

.wrap {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: auto;
}

.block {
  position: absolute;
  left: 0;
  top: 0;
}
