@use 'sass:map';
@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.defaultSchedule {
  padding-bottom: 19px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.title {
  margin-bottom: 17px;
  @include text-16;
  color: var(--light_label_tertiary);
}

.listWrapper {
  position: relative;
  width: 100%;
}

.timerWrapper {
  margin-top: 17px;
  border-radius: 12px;
  overflow: hidden;
  transition: background-color .5s ease-in-out;

  &.isSend {
    background-color: var(--light-background-primary);;
  }
}

.separator {
  position: absolute;
  top: 0;
  left: -$containerPadding;
  width: calc(100% + 40px);
  height: 0.5px;
  background-color: var(--light_additional_separator);
}

.timeWrapper {
  &:not(:last-child) {
    border-bottom: .5px solid var(--light_additional_separator);
  };

  &.isEdit {
    border-bottom: 0;
  }
}

.timeEditMode {
  position: relative;
  text-align: center;
  width: 100%;
  @include text-16;
  color: var(--light_accent_main);
  transition: transform .5s ease-in-out, color .5s ease-in-out;

  &.isEdit {
    transform: translateX(calc(-50% + 47.5px));
  }

  &.isAdd {
    text-align: start;
  }
}

.buttonWrapper {
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonDisabled {
  cursor: default;

  &:disabled {
    opacity: 0;
    transition: opacity .5s ease-in-out;
  }
}

.time {
  background-color: var(--light-background-primary);
  padding: 10px 20px;
  text-align: center;
  position: relative;
}

.deleteButton {
  margin-left: 16px;
  max-width: 24px;
  transition: margin .5s ease-in-out, max-width .5s ease-in-out;

  &.hidden {
    margin-left: 0;
    max-width: 0;
  }
}

.relative {
  position: relative;
}

.skeleton {
  border-radius: 50px;
}

.skeletonForm {
  border-radius: 20px;
}

.jobs {
  color: var(--light_accent_main);
}

.scheduleEditBlock {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 10px 20px;
  background-color: var(--light-background-primary);
  border-radius: 12px;

  &.addRange {
    max-height: 44px;
    border-radius: 0;
    background-color: transparent;
    opacity: 1;
    transition: max-height .5s ease-in-out, padding .5s ease-in-out, opacity .5s ease-in-out;

    &.hidden {
      max-height: 0;
      padding: 0 20px;
      opacity: 0;
    }
  }
}

.timeIsDeleted {
  width: 100%;
  color: var(--light_accent_neutral);
}

.timeIsDeletedSpan {
  position: absolute;
  top: 8px;
  right: 20px;
}

.jobsButton {
  @include text-14;
  border-radius: 50px;
  margin-top: 16px;
  max-height: 44px;
  width: 100%;
  padding: 12px;
  opacity: 1;
  transition: max-height .5s ease-in-out, margin-top .5s ease-in-out, opacity .5s ease-in-out, padding .5s ease-in-out,;

  &.hidden {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
    padding: 0;
  }
}

.jobsButtonIcon {
  display: flex;
  align-items: center;
}

.jobsButtonText {
  margin-left: -10px;
}

.testIcon {
  fill: var(--light_accent_main);
}

.modalHeader {
  margin-bottom: 18px;
  padding-top: 7px;
}

.modalText {
  max-width: 220px;
  margin: 0 auto 22px;
}

.buttonConfirmModal {
  width: 150px;
}
