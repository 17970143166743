@use "sass:map";
@import '@assets/styles/mixins.module.scss';

.selector {
  position: relative;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 53px);
    left: 0;
    right: 0;
    height: 32px;
    border-radius: 8px;
    background-color: var(--black_04);
  };
}

.button {
  width: 100%;
  padding: 12px;
  border-radius: 16px;
  @include text-16;
  color: var(--light-background-primary);
  letter-spacing: -0.31px;

  &Wrapper {
    margin-top: 30px;
  };
};
