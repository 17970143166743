@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.table {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}

.layout {
  margin: auto;
}

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

:global(.BaseTable) {
  -webkit-box-shadow: 0 2px 4px 0 #eee;
  box-shadow: 0 2px 4px 0 #eee;
  background-color: #fff;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}
:global(.BaseTable--disabled) {
  opacity: 0.7;
  pointer-events: none;
}
:global(.BaseTable--dynamic .BaseTable__row) {
  overflow: hidden;
  -ms-flex-align: stretch;
  align-items: stretch;
}
:global(.BaseTable .BaseTable__row--frozen .BaseTable__row-cell-text, )
  :global(.BaseTable:not(.BaseTable--dynamic) .BaseTable__row-cell-text) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
:global(.BaseTable__table) {
  background-color: #fff;
  position: absolute;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
:global(.BaseTable__table-main) {
  outline: 1px solid #eee;
  left: 0;
}
:global(.BaseTable__table-main .BaseTable__header-cell:first-child),
:global(.BaseTable__table-main .BaseTable__row-cell:first-child) {
  padding-left: 15px;
}
:global(.BaseTable__table-main .BaseTable__header-cell:last-child),
:global(.BaseTable__table-main .BaseTable__row-cell:last-child) {
  padding-right: 15px;
}
:global(.BaseTable__table-main .BaseTable__header) {
  background-color: #f8f8f8;
}
:global(.BaseTable__table-frozen-left .BaseTable__body),
:global(.BaseTable__table-frozen-left .BaseTable__header),
:global(.BaseTable__table-frozen-right .BaseTable__body),
:global(.BaseTable__table-frozen-right .BaseTable__header) {
  overflow: hidden !important;
}
:global(.BaseTable__table-frozen-left) {
  -webkit-box-shadow: 2px 0 4px 0 #eee;
  box-shadow: 2px 0 4px 0 #eee;
  top: 0;
  left: 0;
}
:global(.BaseTable__table-frozen-left .BaseTable__header-cell:first-child),
:global(.BaseTable__table-frozen-left .BaseTable__row-cell:first-child) {
  padding-left: 15px;
}
:global(.BaseTable__table-frozen-left .BaseTable__header-row),
:global(.BaseTable__table-frozen-left .BaseTable__row) {
  padding-right: 0 !important;
}
:global(.BaseTable__table-frozen-left .BaseTable__body) {
  overflow-y: auto !important;
}
:global(.BaseTable__table-frozen-right) {
  -webkit-box-shadow: -2px 0 4px 0 #eee;
  box-shadow: -2px 0 4px 0 #eee;
  top: 0;
  right: 0;
}
:global(.BaseTable__table-frozen-right .BaseTable__header-cell:last-child),
:global(.BaseTable__table-frozen-right .BaseTable__row-cell:last-child) {
  padding-right: 15px;
}
:global(.BaseTable__table-frozen-right .BaseTable__header-row),
:global(.BaseTable__table-frozen-right .BaseTable__row) {
  padding-left: 0 !important;
}
:global(.BaseTable__table-frozen-right .BaseTable__body) {
  overflow-y: auto !important;
}
:global(.BaseTable__header) {
  overflow: hidden !important;
}
.BaseTable .BaseTable__body,
.BaseTable .BaseTable__header {
  outline: none;
}
:global(.BaseTable__header-row),
:global(.BaseTable__row) {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:global(.BaseTable__header-row) {
  background-color: #f8f8f8;
  font-weight: 700;
}
:global(.BaseTable__row) {
  background-color: #fff;
}
:global(.BaseTable__row--hovered),
:global(.BaseTable__row:hover) {
  background-color: #f3f3f3;
}
:global(.BaseTable__row-expanded) {
  border-bottom: 1px solid #eee;
}
:global(.BaseTable__header-cell),
:global(.BaseTable__row-cell) {
  min-width: 0;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 7.5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:global(.BaseTable__header-cell--align-center),
:global(.BaseTable__row-cell--align-center) {
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
:global(.BaseTable__header-cell--align-right),
:global(.BaseTable__row-cell--align-right) {
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}
:global(.BaseTable__header-cell) {
  position: relative;
  cursor: default;
}
:global(.BaseTable__header-cell:hover .BaseTable__column-resizer) {
  visibility: visible;
  opacity: 0.5;
}
:global(.BaseTable__header-cell:hover .BaseTable__column-resizer:hover) {
  opacity: 1;
}
:global(.BaseTable__header-cell .BaseTable__sort-indicator) {
  display: none;
}
:global(.BaseTable__header-cell--sortable:hover) {
  background-color: #f3f3f3;
  cursor: pointer;
}
:global(.BaseTable__header-cell--sortable:not(.BaseTable__header-cell--sorting):hover)
  .BaseTable__sort-indicator {
  display: block;
  color: #888;
}
:global(.BaseTable__header-cell--sorting .BaseTable__sort-indicator),
:global(.BaseTable__header-cell--sorting:hover .BaseTable__sort-indicator) {
  display: block;
}
:global(.BaseTable__header-cell--resizing .BaseTable__column-resizer) {
  visibility: visible;
  opacity: 1;
}
:global(.BaseTable__header-cell--resizing .BaseTable__column-resizer:after) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  content: '';
  left: -9999px;
}
:global(.BaseTable__header-cell-text) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
:global(.BaseTable__header-row--resizing .BaseTable__header-cell) {
  background-color: rgba(0, 0, 0, 0);
  cursor: col-resize;
}
:global(.BaseTable__header-row--resizing)
  :global(.BaseTable__header-cell:not(.BaseTable__header-cell--sorting))
  :global(.BaseTable__sort-indicator) {
  display: none;
}
:global(.BaseTable__header-row--resizing)
  :global(.BaseTable__header-cell:not(.BaseTable__header-cell--resizing))
  :global(.BaseTable__column-resizer) {
  visibility: hidden;
}
:global(.BaseTable__column-resizer) {
  width: 3px;
  visibility: hidden;
  background-color: #ccc;
}
:global(.BaseTable__column-resizer:hover) {
  visibility: visible;
  opacity: 1;
}
:global(.BaseTable__footer) {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #fff;
}
:global(.BaseTable__resizing-line) {
  cursor: col-resize;
  position: absolute;
  top: 0;
  background-color: #ccc;
  width: 3px;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}
:global(.BaseTable__empty-laye) {
  background-color: #fff;
}
:global(.BaseTable__empty-layer),
:global(.BaseTable__overlay) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
:global(.BaseTable__overlay) {
  pointer-events: none;
}
:global(.BaseTable__overlay > *) {
  pointer-events: auto;
}

//Table
:global(.BaseTable) {
  background-color: #fff;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
}

:global(.BaseTable__overlay) {
  pointer-events: none;
}

:global(.BaseTable__empty-layer),
:global(.BaseTable__overlay) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

:global(.BaseTable__header) {
  overflow: hidden !important;
}

:global(.BaseTable__header-row) {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--light_additional_separator);
  background-color: var(--light-background-primary);
}

:global(.BaseTable__row) {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--light_additional_separator);
  &:last-child {
    border-bottom: none;
  }
}

:global(.BaseTable__table) {
  background-color: var(--light-background-primary);
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
}

:global(.BaseTable__body) {
  overflow: hidden !important;
}

:global(.BaseTable__table-main) {
  outline: none;
  border-radius: 20px;
  left: 0;
}

:global(.BaseTable__header-cell) {
  min-width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0 16px;
  @include text-14;
  color: var(--light_accent_main);
  border-right: 1px solid var(--light_additional_separator);
  &:last-child {
    border-right: none;
  }
}

:global(.BaseTable__row-cell) {
  min-width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 0;
  @include text-12;
  color: var(--light_label_primary);
  border-right: 1px solid var(--light_additional_separator);
  &:last-child {
    border-right: none;
  }
}

:global(.BaseTable__row-cell-text) {
  max-width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
