@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrap {
  position: fixed;
  bottom: 10px;
  left: -999px;
  padding: 10px;
  border-radius: 10px;
  max-width: 80dvw;
  background-color: var(--light-background-primary);
  animation-name: animation;
  animation-duration: 6s;
  z-index: 9;
}

@keyframes animation {
  from {
    left: -999px;
  }
  25% {
    left: 10px;
  }
  50% {
    left: 10px;
  }
  80% {
    left: 10px;
  }
  to {
    left: -999px;
  }
}

.wrap.error {
  color: var(--light_accent_negative);
}
