@use "sass:map";
@import "@assets/styles/variables.scss";
@import '@assets/styles/mixins.module.scss';

.map {
  width: 100%;
  height: 353px;
  border-radius: inherit;
  margin-bottom: 16px;
  background-color: var(--light-background-primary);

  &Active {
    border: 1px solid var(--light_accent_supplemental);
  }

  &FullScreen {
    height: 100dvh;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.itemList {
  @include text-14;
  text-align: center;
  color: var(--light_label_secondary);
}

.modalHeader,
.modalText {
  margin-bottom: 18px;
}

.popup {
  position: absolute;
  overflow: hidden ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 322px !important;
  gap: 8px;
  top: 0;
  left: 0;
  padding: 11px 20px;
  border-radius: 50px;
  background-color: var(--light-background-primary);
  transform: translate3d(-50%, -100%, 0);

  &Wrapper {
    overflow: hidden !important;
    display: inline-block;
    padding-bottom: 11px;
    @include text-14;
    letter-spacing: -0.15px;
    color: var(--light_accent_main);
  }

  &Text {
    text-align: center;
  }

  &Button {
    position: relative !important;
    top: -3px !important;
    right: 3px !important;
    padding: 0 !important;
    padding-bottom: 11px !important;
    padding-right: 10px !important;
    opacity: 1;

    & > span {
      width: 24px !important;
      height: 24px !important;
      margin: 0 !important;
      background-color: var(--light_accent_main);
    }
  }
}

@supports (-moz-appearance:none) or (-webkit-touch-callout: none) {
  .popupText {
    padding-bottom: 0px !important;
  }

  .popupButton {
    width: 18px !important;
    height: 18px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
  }
}

.wrapperInput {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .input {
    width: 100%;
    padding: 11px 20px;
    @include text-14;
    border: 1px solid var(--overlay_tertiary_12);
    border-radius: 12px;
    color: var(--light_label_primary);
    background-color: var(--light-background-primary);

    &::placeholder {
      color: var(--light_label_tertiary);
    }
  }

  .button {
    padding: 9px 23px;
    border-radius: 50px;
  }
}
