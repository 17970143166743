@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  width: 100%;
  min-height: 95dvh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 50px);
}

.title {
  @include text-20;
  color: var(--light_label_secondary);
}

.modalButton {
  width: 125px;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 82dvh;
}

.messages {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.text {
  border-radius: 16px;
  color: var(--light_label_secondary);
  background-color: var(--light-background-primary);
  padding: 10px 16px;
  @include text-14;
  max-width: 300px;
  margin-bottom: 10px;
  float: left;
}

.textTitle {
  color: var(--light_label_tertiary);
  margin-bottom: 5px;
}

.autor {
  position: relative;
  float: right;
}

.messageBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.message {
  width: 100%;
  padding: 10px 32px;
  background-color: var(--light-background-primary);
  border-radius: 16px;
  min-height: 40px;
  max-height: 120px;
  border: none;
  @include text-16;
}
