@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapper {
  position: relative;
  padding-top: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100dvh;
  max-width: 350px;
  margin: 0 auto;
  gap: 250px;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 100px;
}

.successWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 100px;
}

.title {
  display: block;
  color: var(--light_label_primary);
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.textBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  bottom: 50px;
}

.text {
  @include text-16;
  text-decoration: none;
  display: block;
  text-align: center;
  color: var(--light_accent_main);
  max-width: 250px;
}

.blue {
  color: var(--light_accent_supplemental);
}
