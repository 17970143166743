@use 'sass:map';
@import '@assets/styles/mixins.module.scss';

.title {
  @include text-18;
  width: 100%;
  text-align: center;
}

.header {
  margin-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
};

.wrapper {
  max-height: 70dvh;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
};

.errorMessage {
  margin-bottom: 24px;
  @include text-14;
  color: var(--light_label_secondary);
  text-align: center;
};

.button {
  width: 100%;
  padding: 11px;
  border-radius: 12px;
};
