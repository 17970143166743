@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 320px;
  border-radius: 16px;
}

.inputsBlock {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.input {
  width: 100%;
  padding: 12px 20px;
}

.info {
  @include text-12;
  color: var(--light_label_secondary);
  margin-top: -12px;
  text-align: center;
  max-width: 205px;
}

.link {
  @include text-12;
  color: var(--light_label_tertiary);
  display: inline-block;
}

.error {
  @include text-14;
  color: var(--light_accent_negative);
  text-align: center;
  max-width: 320px;
}
