@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  position: relative;
  padding: 32px;
}

.title {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: -0.52px;
  color: var(--light-background-primary);
}

.text {
  margin-top: 11px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.52px;
  color: var(--light-background-overflow);
}

.img {
  position: absolute;
  width: 7.14vw;
  height: 7.14vw;
  max-width: 100px;
  max-height: 100px;
  min-width: 74px;
  min-height: 74px;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 767px) {
  .wrapper {
    padding: 20px;
  }

  .title {
    font-size: 16px;
  }

  .text {
    margin-top: 10px;
    font-size: 12px;
  }
}
