@use 'sass:map';
@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.wrapper {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 16px;
  background-color: var(--light-background-overflow);
  z-index: 3;

  &.isStatic {
    position: static;
  }
}

.title {
  @include text-18;
  color: var(--light_label_primary);
}

.disabled {
  opacity: 0.5;
}

.separator {
  position: absolute;
  top: 0;
  left: -$containerPadding;
  right: -$containerPadding;
  height: 0.5px;
  background-color: var(--light_additional_separator);
}
