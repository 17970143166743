@use 'sass:map';
@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--light-background-primary);
  padding: 8px 0px;
  border-radius: 20px;
}

.item {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;
  padding: 10px 16px 11.5px;

  &.separatorBig {
    border-bottom: 0.5px solid var(--light_additional_separator);
  }
}

.field {
  @include text-16;
  color: var(--light_label_tertiary);
  white-space: nowrap;
}

.input {
  width: 100%;
  height: auto;
  padding: 0;
  //TODO: посмотреть возможность изменения падинка в input component
  padding-left: 44px !important;
  border-radius: 0 !important;
  color: var(--light_label_primary);
  transition: color 0.5s ease-in-out;

  &::placeholder {
    color: var(--light_label_tertiary);
  }

  &.disabled {
    pointer-events: none;
    color: var(--light_label_tertiary);
  }

  &.nonChange {
    color: var(--light_label_tertiary);
  }
}

.error {
  top: 0;
  right: 0;
}

.separator {
  width: calc(100% - 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  bottom: 0;
  left: 20px;
}

.mainSeparator {
  width: 100%;
  left: 0;
}
