@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 1s linear infinite;
  &.small {
    width: 24px;
    height: 24px;
  }

  &.medium {
    width: 32px;
    height: 32px;
  }

  &.large {
    width: 62px;
    height: 62px;
  }
}

.spinner {
  &.medium {
    transform: scale(1.5);
  }

  &.large {
    transform: scale(2);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
