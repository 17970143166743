@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.item {
  flex: 1 0 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 41.28vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: flex 0.3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(4px);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    transition: filter 0.3s ease-in-out;
  }

  &Focused {
    // flex: 1 0 58.33%;
    &::before {
      filter: blur(0);
    }
  }

  &Unfocused {
    // flex: 1 0 41.67%;
  }

  &Open {
    flex: 1 0 100%;
    &::before {
      filter: blur(0);
    }
  }

  &Close {
    flex: 1 0 0%;
  }

  .title,
  .button {
    transform: translateX(0) translateY(0);
  }
}

.hidden {
  display: none;
}

.itemOne {
  padding-left: 8.5vw;
  padding-right: 20px;
  align-items: flex-start;
  text-align: left;

  &::before {
    background-image: url(../../../../../../assets/images/technician.png);
    background-position: left center;
  }

  &Close {
    padding-left: 0;
    padding-right: 0;
  }

  &Open {
    padding-right: 8.5vw;

    .title {
      transform: translateX(50.6vw) translateY(-11vh);
    }

    .unHidden {
      width: 33vw;
      transform: translateX(50.6vw) translateY(-11.4vh);
    }

    .button {
      transform: translateX(50.6vw) translateY(-11.2vh);
    }
  }
}

.itemTwo {
  padding-right: 8.5vw;
  align-items: flex-end;
  text-align: right;

  &::before {
    background-image: url(../../../../../../assets/images/agency.png);
    background-position: right center;
  }

  &Close {
    padding-left: 0;
    padding-right: 0;
  }

  &Open {
    align-items: flex-start;
    text-align: left;
    padding-left: 8.5vw;

    .title {
      transform: translateX(0.1vw) translateY(-8.8vh);
    }

    .unHidden {
      width: 33vw;
      transform: translateX(0.1vw) translateY(-8.7vh);
    }

    .button {
      transform: translateX(0.1vw) translateY(-8.9vh);
    }
  }
}

.title {
  font-size: 56px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: -0.52px;
  color: var(--light-background-primary);
  transition: opacity 0.3s ease-in-out;

  &Unfocused {
    opacity: 0.5;
  }
}

.text {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.52px;
  color: var(--light-background-primary);
  transition: opacity 0.3s ease-in-out;

  &Unfocused {
    opacity: 0.5;
  }
}

.button {
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.52px;
  color: var(--light_accent_main);
  padding: 19px 99px;
  border-radius: 16px;
  opacity: 1;
  z-index: 2;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  &Unfocused {
    opacity: 0.5;
  }

  &.textWhite {
    color: var(--light-background-primary);
  }
}

.title,
.button {
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .item {
    height: 50vh;
    padding: 20px;
    padding-top: 20.9vh;
    transition: all 0.3s ease-in-out;

    &::before {
      filter: blur(2px);
    }

    &Focused {
      flex: 1 0 58.33%;
      &::before {
        filter: blur(0);
      }
    }

    &Unfocused {
      flex: 1 0 41.67%;
    }

    &Open {
      flex: 1 0 100vh;
      &::before {
        filter: blur(0);
      }
    }

    &Close {
      flex: 1 0 0;
      opacity: 0;
      height: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
    }
  }

  .itemOne {
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    text-align: center;

    &::before {
      background-image: url(../../../../../../assets/images/expertMobile.png);
      background-position: center;
    }

    &Close {
      padding-left: 0;
      padding-right: 0;
    }

    &Open {
      height: 100vh;
      align-items: flex-start;
      text-align: start;

      .title {
        font-size: 32px;
        transform: translateX(0) translateY(21vh);
      }

      .unHidden {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        letter-spacing: -0.52px;
        transform: translateX(0) translateY(22.5vh);
      }

      .button {
        padding: 13px 79.5px;
        transform: translateX(0) translateY(23.4vh);
      }
    }
  }

  .itemTwo {
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    text-align: center;

    &::before {
      background-image: url(../../../../../../assets/images/agencyMobile.png);
      background-position: center;
    }

    &Close {
      padding-left: 0;
      padding-right: 0;
    }

    &Open {
      align-items: flex-start;
      text-align: left;

      .title {
        font-size: 32px;
        transform: translateX(0vw) translateY(22.5vh);
      }

      .unHidden {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500;
        letter-spacing: -0.52px;
        transform: translateX(0vw) translateY(24.1vh);
      }

      .button {
        padding: 13px 79.5px;
        transform: translateX(0vw) translateY(25vh);
      }
    }
  }

  .title {
    font-size: 24px;

    &Unfocused {
      opacity: 0.5;
    }
  }

  .text {
    margin-top: 8px;
    font-size: 16px;

    &Unfocused {
      opacity: 0.5;
    }
  }

  .button {
    margin-top: 16px;
    font-size: 16px;
    padding: 9px 36px;
  }
}
