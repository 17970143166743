@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  position: relative;
  padding: 32px;
}

.titleBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  .title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    letter-spacing: -0.52px;
    color: var(--light-background-primary);
  }

  .img {
    width: 42px;
    height: 42px;
    display: block;
    object-fit: cover;
  }
}

.text {
  margin-top: 11px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.52px;
  color: var(--light-background-overflow);
}

@media (max-width: 767px) {
  .wrapper {
    padding: 20px;
  }

  .titleBlock {
    .title {
      font-size: 16px;
    }

    .img {
      width: 32px;
      height: 32px;
    }
  }

  .text {
    margin-top: 10px;
    font-size: 12px;
  }
}
