@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrap {
  position: relative;
}

.link {
  width: 100%;
  text-decoration: none;
  margin-top: 20px;
  display: block;
}

.button {
  width: 100%;
}
