@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 300px;
  max-width: 350px;
  width: calc(100% - 40px);
}

.block {
  padding: 20px 10px 30px;
}

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.apply {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  @include text-16;
  color: var(--light_label_inert);
}

.applyTime {
  @include text-24;
  color: var(--light_label_secondary);
}

.inputs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  background-color: var(--light_accent_neutral);
  color: var(--light-background-primary);
  border-radius: 30px;
  text-align: center;
}

.picker {
  @include text-18;
  background-color: var(--light_accent_neutral);
  border: none;
  color: var(--light-background-primary);
  appearance: none;
  position: relative;
}

.separator {
  width: 50px;
  height: 1px;
  background-color: var(--light_additional_separator);
}

.error {
  margin-top: 10px;
  @include text-14;
  color: var(--light_accent_negative);
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 40px;
  border-radius: 30px;
  text-align: center;
  background-color: var(--light_accent_neutral);
  color: var(--light-background-primary);
};

.header {
  margin-bottom: 23px;
  padding-left: 20px;
  padding-right: 20px;
};

.title {
  @include text-18;
  width: 100%;
  text-align: center;
};
