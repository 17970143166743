@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 64px 120px;
}

.title {
  position: relative;
  font-size: 32px;
  line-height: 45px;
  font-weight: 700;
  color: var(--light_label_primary);
  letter-spacing: -0.52px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -48px;
    width: 4px;
  }

  &.supplemental::before {
    background-color: var(--light_accent_supplemental);
  }

  &.inert::before {
    background-color: var(--light_label_inert);
  }
}

.infoBlock {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 38px;
  gap: 32px;

  .imageBlock {
    background-position: center;
    background-size: cover;
  }

  .registerBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 72px 48px 56px;
    transition: background-color 0.3s ease-in-out;
    gap: 48px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    &.supplemental {
      background-color: var(--light_accent_supplemental);
    }
    &.inert {
      background-color: var(--light_label_inert);
    }

    &.white {
      background-color: var(--light-background-primary);
    }

    .subtitle {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;
      letter-spacing: -0.52px;
      transition: color 0.3s ease-in-out;
      text-align: center;

      &.supplemental {
        color: var(--light_accent_supplemental);
      }

      &.inert {
        color: var(--light_label_inert);
      }

      &.white {
        color: var(--light-background-primary);
      }
    }

    .link {
      width: 100%;
      text-decoration: none;
    }

    .button {
      width: 100%;
      padding: 19px;
      border-radius: 16px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.42px;
      transition:
        background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    }
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 21px 40px 40px;
  }

  .title {
    font-size: 24px;
    line-height: 37px;

    &::before {
      left: -20px;
    }
  }

  .infoBlock {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 275px repeat(5, auto);
    margin-top: 20px;
    gap: 24px;

    .registerBlock {
      padding: 32px 43px;
      gap: 32px;

      .subtitle {
        font-size: 24px;
      }

      .link {
        max-width: 241px;
      }

      .button {
        padding: 13px;
        font-size: 16px;
        letter-spacing: -0.52px;
      }
    }
  }
}
