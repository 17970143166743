@use 'sass:map';
@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 0 0;
}

.title {
  max-width: 255px;
  @include text-16;
  color: var(--light_label_tertiary);
  text-align: center;
  overflow-wrap: break-word;
}

.typesWrapper {
  width: 100%;
  margin-top: 34px;
  border-radius: 12px;
  background-color: var(--light-background-primary);
  list-style: none;
}

.checkInput {
  display: none;
}

.customCheck {
  border-radius: 50%;
  display: flex;
  border: 2px solid var(--light_label_tertiary);
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  background-color: var(--light-background-primary);
}

.checkInput:checked + .customCheck {
  background-color: var(--light_label_secondary);
  border: none;
}

.button {
  width: 100%;
  margin-top: 16px;
  padding: 7px 0 8px;
  border-radius: 50px;
}

.buttonText {
  @include text-14;
  color: var(--light-background-overflow);
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.notSelected {
  padding: 12px 20px;
  @include text-16;
  color: var(--light_accent_main);
  text-align: center;
}
