@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 40px 120px 60px;
  gap: 20px;
}

.androidBlock {
  justify-content: flex-start;
}

.androidText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.52px;
}

.androidLink {
  text-decoration: none;
  background-color: var(--light_accent_supplemental);
  color: var(--light-background-primary);
  padding: 10px 25px;
  border-radius: 16px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  letter-spacing: -0.52px;
}

.textBlock {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    position: relative;
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;
    color: var(--light_label_primary);
    letter-spacing: -0.52px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -48px;
      width: 4px;
    }

    &.supplemental::before {
      background-color: var(--light_accent_supplemental);
    }

    &.inert::before {
      background-color: var(--light_label_inert);
    }
  }

  .mainText {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    color: var(--light_label_tertiary);
    letter-spacing: -0.52px;
  }

  .link {
    margin-top: 40px;
    width: 100%;
    text-decoration: none;
  }

  .button {
    width: 100%;
    max-width: 241px;
    padding: 19px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: -0.52px;
    padding: 19px;
    border-radius: 16px;
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
  }
}

.pictureBlock {
  position: relative;
  width: calc(100% - 370px);
  height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction: column-reverse;
    padding: 10px 0px 20px;
    gap: 33px;
  }

  .androidBlock {
    flex-direction: row;
    padding: 10px 20px 20px;
  }

  .textBlock {
    width: 100%;
    padding: 0 40px;

    .title {
      font-size: 24px;
      line-height: 37px;

      &::before {
        left: -20px;
      }
    }

    .mainText {
      margin-top: 10px;
      font-size: 16px;
    }

    .link {
      margin-top: 24px;
    }

    .button {
      padding: 13px;
      font-size: 16px;
    }
  }

  .pictureBlock {
    width: 100%;
    height: 104px;
  }
}
