@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--light-background-primary);
  border-radius: 20px;
  transition: max-height 0.5s ease-in-out;
  max-height: 0px;
  overflow: hidden;

  &Active {
    max-height: 275px;
    padding: 20px;
  }
}

.weekButton {
  border-radius: 50%;
  width: 100%;
  color: var(--light_accent_main);
  background-color: var(--light-background-overflow);
}

.paymentButton {
  border-radius: 50%;
  width: 100%;
  color: var(--light_accent_main);
}

.weekIcon {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background-color: var(--gray85);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  @include text-14;
}

.total {
  display: flex;
  padding: 32px 0;
  border-bottom: 1px solid var(--light_additional_separator);
  justify-content: center;
  align-items: center;
  gap: 31px;
}

.totalButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: var(--light_label_primary);
  width: 100px;
  cursor: pointer;
  opacity: 0.5;
}

.totalButton.active {
  opacity: 1;
}

.title {
  @include text-12;
}

.value {
  @include text-18;
}

.separator {
  width: 1px;
  height: 48px;
  background-color: var(--light_additional_separator);
}

.info {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.item {
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
}

.skeleton {
  border-radius: 10px;
}

.description {
  @include text-14;
  color: var(--light_label_tertiary);
}

.grey {
  color: var(--light_label_tertiary);
}

.green {
  color: var(--light_accent_positive);
}

.red {
  color: var(--light_accent_negative);
}
