@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.overlay {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  overflow: hidden;

  &.standard {
    background-color: var(--black_60);
  }

  &.transparent {
    background-color: transparent;
  }

  &.bottomSheet {
    align-items: flex-end;
  }
}

.container {
  position: relative;
  color: var(--light_label_primary);
  background-color: var(--light-background-overflow);

  &.bottomSheet {
    height: auto;
    max-height: calc(100dvh - 5%);
    padding: 20px;
    border-radius: 16px 16px 0 0;
    overflow-y: auto;
    transform: translate(0, 100%);
    transition: transform 0.5s ease-in-out;

    &.active {
      transform: translate(0, 0);
    }
  }

  &.modal {
    padding: 20px;
    height: auto;
    border-radius: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include text-26;
  margin-bottom: 30px;
}

.close {
  background-color: var(--light-background-primary);
  padding: 4px;
}

.content {
  @include text-14;
}

.footer {
  position: relative;
}
