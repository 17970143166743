@use 'sass:map';
@import '@assets/styles/variables.scss';

.footer {
  padding: 24px 0;
  background-color: var(--light_label_primary);
}

.wrapper {
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 120px;
}

.blockLogo {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .text {
    position: relative;
    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.52px;
    color: var(--light-background-overflow);

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      right: 0;
      left: 0;
      height: 1px;
      background-color: var(--light-background-primary);
      z-index: 1;
    }
  }
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;

  .item {
    font-size: 14px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: -0.52px;
    color: var(--light-background-primary);
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 20px 0 10px;
  }

  .wrapper {
    flex-direction: column;
    padding: 0 20px;
  }

  .blockLogo {
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      bottom: -8px;
      right: 0;
      left: 0;
      height: 1px;
      background-color: var(--light-background-primary);
      z-index: 1;
    }

    .text {
      &::before {
        display: none;
      }
    }
  }

  .nav {
    width: 100%;
    margin-top: 18px;
  }

  .list {
    width: 100%;
    gap: 10px;

    .item {
      font-size: 12px;
      text-decoration: none;
    }
  }
}
