@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapped {
  position: relative;
  padding-top: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100dvh;
  gap: 230px;
  max-width: 350px;
  margin: 0 auto;
}

.title {
  margin-bottom: 100px;
}

.textBlock {
  position: absolute;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.text {
  @include text-16;
  text-decoration: none;
  display: block;
  text-align: center;
  color: var(--light_accent_main);
  max-width: 300px;
}

.blue {
  color: var(--light_accent_supplemental);
}
