@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.wrapper {
  padding: 1px 0 20px;
}

.workingAsHeader {
  position: relative;
  padding: 21px 0 13px;
}

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  top: 0;
  left: -$containerPadding;
}

.working {
  display: flex;
  flex-direction: column;
}

.workingTitle {
  @include text-16;
  color: var(--light_accent_main);
  padding-bottom: 16px;
}

.workingButtons {
  display: flex;
  width: 100%;
}

.workingButton {
  width: 50%;
  &:first-child {
    border-radius: 20px 0 0 20px;
  }
  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  &.buttonDisabled {
    pointer-events: none;
  }
}

.documentsWrapper {
  margin-top: 1px;
  list-style: none;
}

.documentType {
  padding: 20px 20px 18.5px;
  border-radius: 20px;
  background-color: var(--light-background-primary);
}

.skeletonWorking {
  padding: 21px 0 13px;
  border-radius: 20px;
}

.skeletonItem {
  padding: 20px 20px;
  border-radius: 20px;
}

.documentType:not(:first-child) {
  margin-top: 16px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperTitle {
  padding: 10px;
  border-radius: 50px;
  background-color: var(--light-background-overflow);
  width: 100%;
}

.title {
  text-align: center;
  @include text-16;
  color: var(--light_accent_main);
}

.button {
  max-width: 24px;
  opacity: 1;
  margin-left: 10px;
  transition: max-width .5s ease-in-out, opacity .5s ease-in-out, margin-left .5s ease-in-out;

  &Hidden {
    max-width: 0;
    opacity: 0;
    margin-left: 0;
  }
}
