@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  padding-top: 50px;
}

@media (max-width: 767px) {
  .wrapper {
    padding-top: 37px;
  }

  .prevButton {
    left: -32px;
    background: none;
    transform: translateY(18px);
  }

  .nextButton {
    right: -32px;
    background: none;
    transform: translateY(18px);
  }

  .prevButton:hover,
  .nextButton:hover {
    background: none;
  }

  .prevButton:disabled,
  .nextButton:disabled {
    cursor: none;
    background: none;
  }
}
