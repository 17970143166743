@use "sass:map";
@import '@assets/styles/mixins.module.scss';

.drumWrapper {
  position: relative;
}

.drum {
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-20px);
  transition: transform 0.3s ease;
  height: 170px;
  perspective: 800px;

  &Touch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &Item {
    position: absolute;
    width: 100%;
    text-align: center;
    transform-origin: center center;
    transition: transform 0.5s ease;

    &:not(:last-child) {
      margin-bottom: 5px;
    };

    $children: (
      1: (12, 0),
      2: (14, 21px),
      3: (18, 44px),
      4: (20, 71px),
      5: (18, 104px),
      6: (14, 131px),
      7: (12, 154px),
    );

    @each $index, $values in $children {
      &:nth-child(#{$index}) {
        @include drum-text-size(nth($values, 1));
        transform: translateY(nth($values, 2));
      };
    };
  }
};
