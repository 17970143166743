@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  width: 100%;
  min-height: 95dvh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 50px);
}

.wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
