@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 64px 120px;

  > .link {
    display: none;
  }
}

.textBlock {
  width: 19.28%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    position: relative;
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;
    color: var(--light_label_primary);
    letter-spacing: -0.52px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -48px;
      width: 4px;
    }

    &.supplemental::before {
      background-color: var(--light_accent_supplemental);
    }

    &.inert::before {
      background-color: var(--light_label_inert);
    }
  }

  .mainText {
    margin-top: 24px;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    color: var(--light_label_tertiary);
    letter-spacing: -0.52px;
  }

  .link {
    margin-top: 40px;
    width: 100%;
    text-decoration: none;

    .button {
      width: 100%;
      padding: 19px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      letter-spacing: -0.42px;
      border-radius: 16px;
      transition:
        background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
    }
  }
}

.pictureBlock {
  width: calc(100% - 20px - 18.86%);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 1px;
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction: column;
    margin: 0 auto;
    padding: 40px;

    > .link {
      display: block;
      margin-top: 32px;
      width: 100%;
      max-width: 241px;
      text-decoration: none;

      .button {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        padding: 13px;
        border-radius: 16px;
        transition:
          background-color 0.3s ease-in-out,
          color 0.3s ease-in-out;
      }
    }
  }

  .textBlock {
    width: 100%;

    .title {
      position: relative;
      font-size: 24px;
      line-height: 37px;
      color: var(--light_label_primary);
      letter-spacing: -0.52px;

      &::before {
        left: -20px;
      }
    }

    .mainText {
      margin-top: 20px;
      font-size: 16px;
      line-height: 32px;
    }

    .link {
      display: none;
    }
  }

  .pictureBlock {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    margin-top: 16px;
    gap: 10px;
  }
}
