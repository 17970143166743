@use 'sass:map';

.wrapper {
  margin: 0 auto;
  padding: 0;
}

.banner {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}
