@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 300px;
  max-width: 350px;
  width: calc(100% - 40px);
}

.title {
  width: 100%;
  text-align: center;
}

.input {
  text-align: center;
  height: 44px;
}

.inputError {
  top: 12px;
}

.error {
  color: var(--light_accent_negative);
}

.text {
  margin-top: 10px;
  @include text-12;
  color: var(--light_label_tertiary);
  text-align: center;
}

.button {
  width: 100%;
  margin-top: 16px;
}
