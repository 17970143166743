@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.title {
  @include text-18;
  color: var(--light_label_primary);
}

.button {
  width: 100%;
  margin-bottom: 21px;
  margin-top: 15px;
  //TODO: проверить стили в кнопке
  padding: 8px 24px !important;
  height: auto !important;
}

.wrapper {
  position: relative;
  padding: 27px 0 0;
}

.separator {
  position: absolute;
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  top: 0px;
  left: -$containerPadding;
}
