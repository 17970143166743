@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 80px 120px;
}

.title {
  position: relative;
  font-size: 32px;
  line-height: 45px;
  font-weight: 700;
  color: var(--light_label_primary);
  letter-spacing: -0.52px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -48px;
    width: 4px;
  }

  &.supplemental::before {
    background-color: var(--light_accent_supplemental);
  }

  &.inert::before {
    background-color: var(--light_label_inert);
  }
}

.reviewsBlock {
  margin-top: 30px;
}

@media (max-width: 767px) {
  .wrapper {
    padding: 42px 40px 38px;
  }

  .title {
    font-size: 24px;
    line-height: 37px;

    &::before {
      left: -20px;
    }
  }

  .reviewsBlock {
    margin-top: 16px;
  }
}
