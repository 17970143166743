@mixin text-style($size, $height, $weight, $letter) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
  letter-spacing: $letter;
}

@mixin text-26() {
  @include text-style(26px, 30px, 500, -0.52px);
}

@mixin text-24() {
  @include text-style(24px, 28px, 500, -0.62px);
}

@mixin text-20() {
  @include text-style(20px, 28px, 500, -0.62px)
}

@mixin text-18() {
  @include text-style(18px, 22px, 500, -0.42px);
}

@mixin text-16() {
  @include text-style(16px, 20px, 500, -0.52px);
}

@mixin text-14() {
  @include text-style(14px, 18px, 500, -0.42px);
}

@mixin text-13() {
  @include text-style(13px, 16px, 600, -0.42px);
}

@mixin text-12() {
  @include text-style(12px, 16px, 500, -0.52px);
}

@mixin drum-text-size($size) {
  @if $size == 12 {
    @include text-12;
    color: var(--light_label_primary_10);
  } @else if $size == 14 {
    @include text-14;
    color: var(--light_label_primary_20);
  } @else if $size == 18 {
    @include text-18;
    color: var(--light_label_primary_30);
  } @else if $size == 20 {
    @include text-20;
    color: var(--light_label_primary);
  }
}
