@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 300px;
  max-width: 350px;
  width: calc(100% - 40px);
  background: var(--light-background-primary);
}

.header {
  position: relative;
}

.title {
  @include text-18;
  width: 100%;
}

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  bottom: -15px;
  left: -20px;
}

.module {
  position: relative;

  & .separator {
    left: 0;
    bottom: 0;
    width: calc(100% + 20px);
  }
}

.block {
  padding: 16px 0;
}

.item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
}

.label {
  @include text-16;
  color: var(--light_label_tertiary);
}

.value {
  @include text-16;
  color: var(--light_accent_main);
  max-width: 50%;
  word-wrap: break-word;
  text-align: right;
}
