.wrapper {
  position: relative;
}

.button {
  position: absolute;
  top: 19px;
  z-index: 1;
}

.buttonSize {
  font-size: 0;
}

.buttonLeft {
  left: 0;
}

.buttonRight {
  right: 0;
}
