@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.wrap {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.text {
  @include text-18;
  color: var(--light_label_primary);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
}

.documentsWrapper {
  margin-top: 1px;
  list-style: none;
}

.documentType {
  padding: 20px 20px 18.5px;
  border-radius: 20px;
  background-color: var(--light-background-primary);
}

.skeletonWorking {
  padding: 21px 0 13px;
  border-radius: 20px;
}

.skeletonItem {
  padding: 20px 20px;
  border-radius: 20px;
}

.documentType:not(:first-child) {
  margin-top: 16px;
}

.documentHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperTitle {
  position: relative;
  padding: 10px;
  border-radius: 50px;
  background-color: var(--light-background-overflow);
  width: 100%;
}

.contactIcon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.title {
  text-align: center;
  @include text-16;
  color: var(--light_accent_main);
}

.button {
  max-width: 24px;
  opacity: 1;
  margin-left: 10px;
  transition:
    max-width 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    margin-left 0.5s ease-in-out;

  &Hidden {
    max-width: 0;
    opacity: 0;
    margin-left: 0;
  }
}
