@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  border-bottom: 0.5px solid var(--light_additional_separator);
}

.header {
  height: 60px;
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  display: block;
}

.headerButton {
  color: var(--light_label_secondary);
}

.content {
  position: relative;
  margin-top: 60px;
  min-height: calc(100dvh - 60px);
}

.action {
  padding-top: 20px;
  display: flex;
  gap: 10px;
}

.search {
  height: 48px;
}

.searchIcon {
  position: absolute;
  top: 18px;
  left: 10px;
}

.addButton {
  width: 70px;
  border-radius: 16px;
}

.chats {
  padding-top: 20px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;

  &:last-child {
    padding-top: 10px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px 20px;
  width: 100%;
  border-radius: 16px;
  background-color: var(--light-background-primary);

  &.close {
    opacity: 0.7;
  }
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  @include text-14;
  color: var(--light_label_secondary);
}

.time {
  @include text-12;
  color: var(--light_label_tertiary);
}

.itemText {
  @include text-12;
  color: var(--light_label_tertiary);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.emptyText {
  @include text-14;
  color: var(--light_label_tertiary);
  text-align: center;

  & .emptyAction {
    color: var(--light_accent_supplemental);
    cursor: pointer;
  }
}

.skeleton {
  border-radius: 20px;
  margin: 20px 0;
}
