@use 'sass:map';
@import '@assets/styles/variables.scss';

@font-face {
  font-family: 'Inter';
  src:
    local('InterThin'),
    local('Inter-Thin'),
    url('@assets/fonts/Inter-Thin.woff2') format('woff2'),
    url('@assets/fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('InterRegular'),
    local('Inter-Regular'),
    url('@assets/fonts/Inter-Regular.woff2') format('woff2'),
    url('@assets/fonts/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('InterMedium'),
    local('Inter-Medium'),
    url('@assets/fonts/Inter-Medium.woff2') format('woff2'),
    url('@assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('InterSemiBold'),
    local('Inter-SemiBold'),
    url('@assets/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('@assets/fonts/Inter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src:
    local('InterBold'),
    local('Inter-Bold'),
    url('@assets/fonts/Inter-Bold.woff2') format('woff2'),
    url('@assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: Inter;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  position: relative;
}

.hideWrapper {
  min-height: 0px;
}

.content {
  flex: 1 0 auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

ul {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

img,
svg {
  vertical-align: none;
}

:root {
  /*colors*/
  --black: #000000;
  --black_60: rgba(0, 0, 0, 0.6);
  --black_08: rgba(0, 0, 0, 0.08);
  --black_04: rgba(0, 0, 0, 0.04);
  --overlay_tertiary_12: rgba(0, 0, 0, 0.12);
  --light-background-overflow: #f3f3f3;
  --light-background-secondary: #f0f0f0;
  --light-background-primary: #fff;
  --light_label_primary: #222429;
  --light_label_primary_30: rgba(34, 36, 41, 0.3);
  --light_label_primary_20: rgba(34, 36, 41, 0.2);
  --light_label_primary_10: rgba(34, 36, 41, 0.1);
  --light_label_tertiary: #9e9e9e;
  --light_accent_main: #595b61;
  --light_additional_separator: rgba(0, 0, 0, 0.08);
  --light_accent_negative: #cf5656;
  --light_accent_supplemental: #4872ab;
  --light_accent_positive: #66bb69;
  --light_accent_neutral: #c89b29;
  --light_label_secondary: #3e4958;
  --light_label_inert: #97adb6;
  --light_accent_neutral: #c89b29;
  --dark_blue: rgb(0, 62, 200);
  --gray85: #d9d9d9;
}
