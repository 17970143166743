@use 'sass:map';

.container {
  position: relative;
  width: 100%;
}

.sliderContainer {
  overflow: hidden;
  width: 100%;
}

.sliderWrapper {
  display: flex;
  align-items: stretch;
  transition: transform 0.3s ease-in-out;
}

.slide {
  display: flex;
  flex: 0 0 auto;
  transition: opacity 0.5s ease-in-out;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.prevButton:hover,
.nextButton:hover {
  background: rgba(0, 0, 0, 0.8);
}

.prevButton:disabled,
.nextButton:disabled {
  background: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}
