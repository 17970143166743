@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  max-width: 350px;
  min-width: calc(100% - 40px);
  background-color: var(--light-background-primary);
}

.spinner {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  position: relative;
}

.header {
  position: relative;
}

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.main {
  display: flex;
  flex-direction: column;
}

.text {
  padding: 16px 0;
  @include text-16;
  color: var(--light_label_tertiary);
}

.select {
  border: 1px solid var(--light_additional_separator);
  background-color: var(--light-background-primary);
}

.selectList {
  background-color: var(--light-background-primary);
}

.buttonBlock {
  display: flex;
  gap: 10px;
  padding-top: 20px;
}

.button {
  &:last-child {
    width: 100%;
  }
}
