@use "sass:map";
@import "@assets/styles/variables.scss";
@import '@assets/styles/mixins.module.scss';

.wrapper {
  padding-bottom: 20px;
}

.map {
  position: relative;
  width: 100%;
  border-radius: 20px;

  &FullScreen {
    position: fixed;
    width: 100vw;
    height: 100dvh;
    top: 0;
    left: 0;
    z-index: 5;
  }
}

.rectangle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 353px;
  top: 0;
  left: 0;
  background-color: var(--black_60);
  pointer-events: auto;
  border-radius: 20px;
  z-index: 2;

  &Disable {
    display: none;
  }

  &Text {
    @include text-24;
    color: var(--light-background-secondary);
    text-align: center;
    cursor: default;
  }
}

.mainButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 100%;
  gap: 2px;
  z-index: 6;
}

.buttonCancel {
  padding: 5px 20px;
  border-radius: 0 50px 50px 0;
}

.buttonCancelMainButton {
  border-radius: 50px 0 0 50px;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  gap: 16px;
}

.textMode {
  padding: 8px 20px;
  border-radius: 20px;
  text-align: center;
  @include text-14;
  background-color: var(--light-background-primary);
  pointer-events: none;
}

.spinnerWrapper {
  padding: 5px 20px;
  border-radius: 20px;
  background-color: var(--light-background-primary);
}

.skeleton {
  border-radius: 50px;
}

.skeletonForm {
  border-radius: 20px;
}

.modalHeader {
  margin-bottom: 18px;
  padding-top: 7px;
}

.modalText {
  max-width: 220px;
  margin: 0 auto 22px;
}

.buttonConfirmModal {
  width: 150px;
}
