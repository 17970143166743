@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 350px;
  background-color: var(--light-background-overflow);
}

.header {
  text-align: center;
}

.content {
  position: relative;
}

.input {
  height: 48px;
}

.error {
  padding-top: 10px;
  text-align: center;
  color: var(--light_accent_negative);
}

.button {
  margin-top: 16px;
  width: 100%;
}
