/*padding*/
/**
* font-size = viewport > $widthMax ? $fontMax : viewport < $widthMin ? $fontMin : fluid
* $fallback - initial value for old browsers (without support of vw units)
**/
.financeLabel {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.52px;
  color: var(--light_label_tertiary);
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.radioInput {
  display: none;
}

.customRadio {
  border-radius: 50%;
  display: flex;
  border: 2px solid var(--light_label_tertiary);
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  background-color: var(--light-background-primary);
}

.radioInput:checked + .customRadio {
  background-color: var(--light_label_secondary);
  border: none;
}

.selectBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 16px;
}

.select {
  margin-bottom: 0;
}

.selectInput {
  position: relative;
  background-color: var(--light-background-primary);
  border: 1px solid var(--light_additional_separator);
}

.selectList {
  position: relative;
  background-color: var(--light-background-primary);
  border: 1px solid var(--light_additional_separator);
}