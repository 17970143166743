@use "sass:map";

.wrapper {
  margin-bottom: 19px;
}

.skeleton {
  border-radius: 50px;
}

.skeletonForm {
  border-radius: 20px;
}

.modalHeader {
  margin-bottom: 18px;
  padding-top: 7px;
}

.modalText {
  max-width: 310px;
  margin: 0 auto 22px;
}

.buttonConfirmModal {
  width: 150px;
}
