@use 'sass:map';
@import '@assets/styles/mixins.module.scss';

.modal {
  background-color: var(--light-background-primary);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-size: 0;
}

.text {
  @include text-14;
  color: var(--light_accent_main);
}

.header {
  margin-bottom: 0;
}
