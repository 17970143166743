@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.wrap {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.text {
  @include text-18;
  color: var(--light_label_primary);
}

.button {
  max-height: 34px;
}

.buttonDisabled {
  pointer-events: none;
}

.skeleton {
  border-radius: 50px;
}

.statusTitle {
  padding-bottom: 8px;
  text-align: left;
  &:last-child {
    padding-bottom: 0;
  }
}
