@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.main {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  padding-top: 16px;

  &.hasTop {
    padding-top: 50px;
  }
}

.top {
  color: var(--light_label_tertiary);
  position: absolute;
  top: 16px;
  left: 0;
}

.upload {
  position: relative;
}

.uploadButton {
  width: 156px;
  height: 40px;
  border-radius: 12px;
  border: none;
  padding: 8px 24px;
  color: var(--light-background-primary);
  background-color: var(--light_accent_supplemental);
  @include text-14;
  cursor: pointer;
}

.icon {
  position: absolute;
  top: 8px;
  right: 24px;
}

.label {
  @include text-14;
  color: var(--light_label_tertiary);
  max-width: 40%;
  word-wrap: break-word;
}

.text {
  margin-top: 16px;
  color: var(--light_label_tertiary);
  @include text-12;
}
