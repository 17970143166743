@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapper {
  position: relative;
  width: 100%;
}

.input {
  padding: 18px 20px;
  border: none;
  border-radius: 16px;
  width: 100%;
  height: 60px;
  color: var(--light_label_primary);
  @include text-16;

  &.beforeIcon {
    padding-left: 36px;
  }
}

.icon {
  position: absolute;
  top: -3px;
  left: 0;
}

.phoneInput::placeholder {
  @include text-14;
}

.input::placeholder {
  @include text-16;
}

.error {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: right .5s ease-in-out;
}

.errorFocused {
  right: 50px;
}

.standard {
  width: 100%;
}

.group {
  & .input {
    border-radius: 0;
  }

  &:first-child .input {
    border-radius: 16px 16px 0 0;
  }

  &:last-child {
    & .input {
      border-radius: 0 0 16px 16px;
    }
  }

  & .top {
    display: none;
  }

  &:first-child .top {
    @include text-14;
    position: absolute;
    color: var(--light_label_tertiary);
    top: -25px;
    left: 5px;
    display: block;
  }
}

.separator {
  background-color: var(--light_additional_separator);
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;

  &.all {
    width: 100%;
  }

  &.short {
    width: calc(100% - 20px);
    left: 20px;
  }
}
