@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  left: -20px;
}

.wrap {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.text {
  @include text-18;
  color: var(--light_label_primary);
}

.optionButton {
  width: 100%;
  margin-bottom: 20px;
}

.wrapper {
  position: relative;
  height: 24px;
  padding: 12px;
  margin: 16px 0;
}

.title {
  text-align: center;
  @include text-14;
  color: var(--light_accent_main);
}

.button {
  position: absolute;
  z-index: 1;
  top: 12px;
  cursor: pointer;
}

.buttonLeft {
  left: 0;
}

.buttonRight {
  right: 0;
}

.graph {
  height: 300px;
  padding: 20px 0;

  &.graphFetching {
    pointer-events: none;
    opacity: 0.5;
  }
}
