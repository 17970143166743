@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.buttonBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.button {
  width: 58px;
  height: 32px;
  border-radius: 2px;
  background-color: var(--light-background-overflow);

  &:first-child {
    border-radius: 16px 2px 2px 16px;
  }
}

.skeleton {
  border-radius: 20px;
  margin: 20px 0;
}

.wrap {
  height: 100%;
  width: 100%;
  min-height: 360px;
  padding: 20px 0;
}

.empty {
  display: flex;
  height: 360px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--light-background-primary);
  border-radius: 20px;
}

.finalComissionChanges {
  color: var(--light_label_tertiary);
}
