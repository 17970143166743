@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 300px;
  max-width: 350px;
  width: calc(100% - 40px);
  background: var(--light-background-primary);
  max-height: 80dvh;
  overflow-x: hidden;
  overflow-y: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  position: relative;
  margin-bottom: 16px;
}

.title {
  @include text-18;
  width: 100%;
}

.separator {
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  width: calc(100% + 40px);
  height: 0.5px;
  position: absolute;
  background: var(--light_additional_separator);
  bottom: -15px;
  left: -20px;
}

.separatorBlock {
  position: relative;
}

.picker {
  position: relative;
  margin-bottom: 16px;

  & .separator {
    left: 0;
  }
}

.input {
  border-radius: 16px;
  border: 1px solid var(--light_additional_separator);
  color: var(--light_accent_main);
  height: 40px;
  width: 100%;
  padding: 10px 20px;
  margin-top: 16px;

  &::placeholder {
    @include text-16;
    color: var(--light_label_tertiary);
  }

  &.emptyMargin {
    margin-top: 0;
  }
}

.error {
  top: 26px;
  &.emptyMargin {
    top: 11px;
  }
}

.footer {
  padding-top: 16px;
}

.submit {
  width: 100%;
}

.fileInput {
  display: none;
}

.upload {
  position: relative;
}

.errorMessage {
  @include text-14;
  color: var(--light_accent_negative);
  text-align: center;
  max-width: 320px;
  padding-bottom: 16px;
}

.docLink {
  width: 100%;
  @include text-16;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
  background-color: var(--light_accent_supplemental);
  color: var(--light-background-primary);
  border-radius: 12px;
  padding: 8px 0;
  text-decoration: none;
  cursor: pointer;
}
