@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px;
  border-radius: 20px;

  &Open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: .5px solid var(--light_additional_separator);
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.text {
  @include text-16;
  color: var(--light_accent_main);
}

.form {
  display: flex;
  flex-direction: column;
  padding: 12px 20px 0;
  background-color: var(--light-background-primary);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  opacity: 1;
  max-height: 400px;
  transition: opacity .5s ease-in-out, max-height .5s ease-in-out, padding .5s ease-in-out;

  &.formDisabled {
    pointer-events: none;
  };

  &.hidden {
    opacity: 0;
    max-height: 0;
    padding: 0 20px;
  }
}

.topPlaceholder {
  @include text-16;
  color: var(--light_label_tertiary);
  display: block;
  margin-bottom: 17px;
}

.selector {
  max-width: 100%;

  &Header {
    color: var(--light_label_primary);
    transition: color .5s ease-in-out;

    &.disabled {
      color: var(--light_label_tertiary)
    }
  }
}

.error {
  color: var(--light_accent_negative);
  margin-bottom: 16px;
  text-align: center;
  display: block;
  word-wrap: break-word;
}

.block {
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: .5px solid var(--light_additional_separator);
  }
}

.input {
  @include text-16;
  color: var(--light_label_primary);
  height: 40px;
  border-radius: 12px;
  background-color: var(--light-background-primary);
  border: 0.5px solid var(--light_additional_separator);
  transition: color 0.5s ease-in-out;

  &::placeholder {
    color: var(--light_label_tertiary);
  }

  &.disabled {
    pointer-events: none;
    color: var(--light_label_tertiary);
  }
}

.inputError {
  top: 46px;
}

.financeBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  padding-bottom: 19px;
  border-bottom: .5px solid var(--light_additional_separator);
}

.financeLabel {
  @include text-16;
  color: var(--light_label_tertiary);
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.radioInput {
  display: none;
}

.customRadio {
  border-radius: 50%;
  display: flex;
  border: 2px solid var(--light_label_secondary);
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  background-color: var(--light-background-primary);
  transition: border-color .5s ease-in-out;

  &.disabled {
    border-color: var(--light_label_tertiary);
  }
}

.radioInput:checked + .customRadio {
  background-color: var(--light_label_secondary);
  border: none;
  transition: background-color .5s ease-in-out;

  &.disabled {
    background-color: var(--light_label_tertiary);
  }
}

.arrow {
  transition: transform .5s ease-in-out;

  &Rotated {
    transform: rotate(180deg);
  };
};
