@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

$headerHeight: 60px;

.header {
  height: $headerHeight;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.title {
  @include text-26;
  color: var(--light_label_primary);
}

.title::first-letter {
  text-transform: uppercase;
}
