@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.common {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 8px;
  cursor: pointer;
}

.standard {
  @include text-16;
  &.l {
    padding: 0 32px;
    height: 60px;
    border-radius: 16px;
  }
  &.m {
    padding: 12px 24px;
    border-radius: 16px;
    height: 44px;
  }
  &.s {
    padding: 8px 20px;
    height: 40px;
    border-radius: 12px;
  }
}

.icon {
  border-radius: 50px;
  font-size: 24px;
  &.l {
    padding: 14px 16px;
  }
  &.m {
    padding: 12px;
  }
  &.s {
    padding: 8px;
  }
}

.common:disabled {
  opacity: 0.3;
}

.grey {
  background: var(--light_accent_main);
  color: var(--light-background-primary);
}

.lightGrey {
  background: var(--light_label_tertiary);
  color: var(--light-background-primary);
}

.green {
  background: var(--light_accent_positive);
  color: var(--light-background-primary);
}

.red {
  background: var(--light_accent_negative);
  color: var(--light-background-primary);
}

.blue {
  background: var(--light_accent_supplemental);
  color: var(--light-background-primary);
}

.darkBlue {
  background: var(--dark_blue);
  color: var(--light-background-primary);
}

.white {
  background: var(--light-background-primary);
  color: var(--light_label_primary);
}

.slightlyDarkWhite {
  background-color: var(--light-background-secondary);
  color: var(--light_label_secondary);
}

.supplemental {
  background-color: var(--light_accent_supplemental);
  color: var(--light-background-primary);
}

.inert {
  background-color: var(--light_label_inert);
  color: var(--light-background-primary);
}

.transparent {
  background-color: transparent;
  padding: 0;
  color: var(--light_label_primary);
}
