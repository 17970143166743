@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.wrapper {
  min-height: calc(100dvh - 60px);
}

.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.spinner {
  min-height: calc(100dvh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
