@use 'sass:map';

.wrapper {
  min-height: 100vh;
  display: flex;
  position: relative;
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }
}
