@use 'sass:map';
@import '@assets/styles/variables.scss';

.menuBlock {
  position: relative;
}

.menuIcon {
  cursor: pointer;
}

.menu {
  width: 100%;
  height: 100dvh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 26px;
}

.content {
  padding-top: 78px;
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 48px;
  cursor: pointer;

  &.profileError {
    pointer-events: none;
  }
}

.item:first-child {
  margin-top: 0px;
}

.title {
  color: var(--light_label_primary);
  font-size: 18px;
  padding-left: 16px;

  &.profileError {
    color: var(--light_accent_negative);
  }
}

.link {
  position: relative;
}

.errorBadge {
  position: absolute;
  right: 0px;
  top: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--light_accent_negative);
}
