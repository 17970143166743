@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.select {
  position: relative;
  display: block;
  min-width: 220px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.selectHead {
  display: flex;
  align-items: center;
  @include text-16;
  width: 100%;
  max-width: 100%;
  height: 40px;
  border-radius: 12px;
  padding: 9px 20px;
  color: var(--light_accent_main);
  background: var(--light-background-overflow);
  cursor: pointer;
  position: relative;
}

.icon {
  position: absolute;
  top: 8px;
  right: 20px;
  transition: transform .5s ease-in-out, fill .5s ease-in-out;

  &Rotated {
    transform: rotate(180deg);
  };
}

.selectHead.open {
  border-radius: 12px 12px 0 0;
}

.selectList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--light-background-overflow);
  border-radius: 10px;
  margin-top: 5px;
  max-height: 205px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 100;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--light_accent_main);
  scrollbar-color: dark;
  scrollbar-width: thin;
  overscroll-behavior: contain;
  opacity: 1;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out, max-height .5s ease-in-out, border .5s ease-in-out;

  &.hidden {
    max-height: 0;
    opacity: 0;
    transform: translateY(-50%);
  }
}

.selectList.open {
  border-radius: 0 0 12px 12px;
}

.selectList::-webkit-scrollbar {
  width: 7px;
  background-color: #f8f9fa;
  padding: 5px;
}

.selectList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #d9d9d9;
}

.selectList .selectItem {
  position: relative;
  height: 40px;
  border-top: 1px solid rgba(224, 229, 231, 0.5);
  padding: 10px 20px;
  cursor: pointer;
  list-style-type: none;
}

.selectList .selectItem:hover {
  background-color: rgba(224, 229, 231, 0.5);
}

.placeholder {
  color: var(--light_label_tertiary);
}
