@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.card {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background-color: var(--light-background-primary);
  padding: 38px 48px;
  max-height: 190px;
  max-width: 151px;
  color: var(--light_label_secondary);
  margin-bottom: 100px;
}

.icon {
  fill: var(--light_label_secondary);
}

.card:active {
  background: var(--light_accent_main);
  color: var(--light-background-primary);

  .text {
    color: var(--light-background-primary);
  }

  .icon {
    fill: var(--light-background-primary);
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}

.title {
  display: block;
}

.text {
  text-align: center;
  @include text-14;
  color: var(--light_label_tertiary);
}
