@use 'sass:map';
@import '@assets/styles/variables.scss';

.container {
  background-color: var(--light-background-overflow);
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.notMainPage {
  padding: 0 $containerPadding;
  max-width: 1140px;
}
