@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 300px;
  max-width: 350px;
  width: calc(100% - 40px);
}

.title {
  width: 100%;
  @include text-18;
  text-align: center;
}

.text {
  @include text-14;
  text-align: center;
  color: var(--light_label_secondary);
}

.hint {
  @include text-12;
  color: var(--light_label_tertiary);
  text-align: center;
  margin-top: 14px;
}

.buttonGroup {
  margin-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.button {
  width: 150px;
}
