@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  width: 100%;
  padding: 0 44px;
}

.header {
  @include text-18;
  justify-content: center;
  text-align: center;
  color: var(--light_label_secondary);
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  padding: 0 30px;
}

.item {
  display: flex;
  gap: 30px;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--light_additional_separator);
  @include text-16;
  color: var(--light_accent_main);
  cursor: pointer;

  &.currentWeek {
    color: var(--light_accent_supplemental);
  }
}

.weekIcon {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
  background-color: var(--gray85);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--black);
  @include text-14;
}
