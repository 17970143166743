@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.topLabel {
  position: absolute;
  top: 16px;
  left: 0;
  @include text-16;
  color: var(--light_label_tertiary);
}

.button {
  position: relative;
  padding-top: 50px;
}

.block {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 12px;
  background-color: var(--light_accent_main);
  color: var(--light-background-primary);
  cursor: pointer;
}

.input {
  width: 90px;
  text-align: center;
  color: var(--light-background-primary);
  background-color: var(--light_accent_main);
  border: none;
  cursor: pointer;

  &::placeholder {
    color: var(--light-background-primary);
    text-align: center;
  }
}

.header {
  text-align: center;
}
.wrapper {
  display: block;
  margin-bottom: 25px;
}

.calendar {
  display: block;
}

.apply {
  width: 100%;
}

//Calendar styles

:global(.react-calendar__navigation) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

:global(.react-calendar__navigation__label) {
  border: none;
  color: var(--light_accent_main);
  background-color: transparent;
  @include text-16;
}

:global(.react-calendar__navigation__next2-button),
:global(.react-calendar__navigation__prev2-button) {
  display: none;
}

:global(.react-calendar__month-view__weekdays) {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light_additional_separator);
}

:global(.react-calendar__month-view__weekdays__weekday) {
  color: var(--light_label_inert);
  text-align: center;
  @include text-13;

  & abbr {
    text-decoration: none;
  }
}

:global(.react-calendar__tile) {
  background-color: transparent;
  color: var(--light_label_secondary);
  border: none;
  @include text-16;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;

  &:disabled {
    background-color: var(--light_accent_negative);
  }
  & abbr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
}

:global(.react-calendar__month-view__days__day--neighboringMonth) {
  color: var(--light_label_inert);
}

:global(.react-calendar__tile--active abbr) {
  background-color: var(--light_accent_main);
  color: var(--light-background-primary);
  border-radius: 50%;
}

:global(.react-calendar__month-view__days) {
  border-bottom: 1px solid var(--light_additional_separator);
}

:global(.react-calendar__navigation__next-button),
:global(.react-calendar__navigation__prev-button) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: transparent;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

:global(.react-calendar__navigation__next-button) {
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: -4px;
    right: 0;
    background: url('@assets/images/m_arrow_right_circile.png');
  }
}

:global(.react-calendar__navigation__prev-button) {
  &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: -4px;
    left: 0;
    background: url('@assets/images/m_arrow_left_circile.png');
  }
}
