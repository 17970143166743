@use 'sass:map';
@import '@assets/styles/mixins.module.scss';
@import '@assets/styles/variables.scss';

.wrapper {
  margin-bottom: 20px;
}

.button {
  width: 100%;
  margin-top: 16px;
  padding: 7px 0 8px;
  border-radius: 50px;
}

.buttonText {
  @include text-14;
  color: var(--light-background-overflow);
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.skeleton {
  border-radius: 50px;
}

.skeletonForm {
  border-radius: 20px;
}

.skeletonAddressButton {
  border-radius: 16px;
  margin-top: 16px;
}

.modalHeader {
  margin-bottom: 18px;
  padding-top: 7px;
}

.modalText {
  max-width: 220px;
  margin: 0 auto 22px;
}

.buttonConfirmModal {
  width: 150px;
}
