@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.h3 {
  font-size: 16px;
  font-weight: normal;
  margin: 15px 0;
}

.p {
  padding-bottom: 5px;

  &:last-child {
    padding-bottom: 0;
  }
}
