@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  padding: 32px 8.5vw;
  z-index: 2;
  transition: opacity 0.3s ease-in-out transform 0.3s ease-in-out;

  &.opacity {
    opacity: 0.5;
  }

  &.positionChange {
    transform: translateX(50.6vw);
  }
}

.mainLine {
  display: flex;
  align-items: center;
  gap: 40px;
}

.text {
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.52px;
  text-decoration: none;
  display: block;
  color: var(--light-background-primary);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--light_accent_supplemental);
  }
}

.button {
  margin-top: 48px;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: -0.52px;
  color: var(--light-background-primary);

  &.hidden {
    display: none;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 24px 20px;

    &.positionChange {
      transform: none;
    }
  }

  .text {
    font-size: 16px;
  }

  .button {
    margin-top: 203px;
    font-size: 16px;
  }
}
