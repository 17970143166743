@use 'sass:map';
@import '@assets/styles/variables.scss';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 34px;
  gap: 24px;
  justify-content: flex-start;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.number {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.52px;
}

.title {
  font-size: 24px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: -0.52px;
}

.text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.52px;
}
