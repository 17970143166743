@use 'sass:map';
@import '@assets/styles/variables.scss';
@import '@assets/styles/mixins.module.scss';

.modal {
  min-width: 300px;
  max-width: 350px;
  background: var(--light-background-primary);
}

.header {
  margin-bottom: 23px;
  padding-left: 20px;
  padding-right: 20px;
};

.title {
  @include text-18;
  width: 100%;
  text-align: center;
}
